import { supabase } from '~/lib/supabase'
import { getHash } from '~/utils'
import { fold } from 'json-origami'

function sortObjectByKeys(obj: Record<string, any>): Record<string, any> {
	const sortedKeys = Object.keys(obj).sort()
	const result: Record<string, any> = {}
	sortedKeys.forEach(key => {
		result[key] = obj[key]
	})
	return result
}

interface ComponentData {
	hash: string
	data: Record<string, any> | null
}

interface Store {
	[key: string]: ComponentData
}

export const sendDataToSupabase = async (store: Store) => {
	const keys = ['audiofp', 'fonts', 'canvas', 'device', 'navigator', 'webgl', 'speech_synthesis']
	const combinedData = keys.reduce((acc, key) => {
		if (store[key] && store[key].hash) {
			acc[key] = store[key].hash
		}
		return acc
	}, {})

	const flattenedData = fold(store)
	const combinedHash = await getHash(combinedData)
	const sortedData = sortObjectByKeys(flattenedData)

	const { data: insertedData, error: insertError } = await supabase
		.from('fingerprints')
		.insert([{
			data: sortedData,
			hash: combinedHash,
			browser: store?.device?.data?.browser?.name || '',
			browser_version: store?.device?.data?.browser?.version || '',
			os: store?.device?.data?.os?.name,
			os_version: store?.device?.data?.os?.version || '',
			device_type: store?.device?.data?.device?.type || 'desktop',
			device_model: store?.device?.data?.device?.model || 'desktop',
			user_agent: store?.navigator?.data.userAgent || '',
			language: store?.navigator?.data.language || '',
		}])
		.select()

	if (insertError) {
		console.error('Error inserting data into Supabase:', insertError)
		return
	}

	const fingerprintId = insertedData?.[0]?.id

	if (fingerprintId) {
		const details = Object.entries(store).map(([name, componentData]) => {
			const { hash, data: componentDataContent } = componentData
			const flattenedData = componentDataContent ? fold(componentDataContent) : null
			const totalParams = flattenedData ? Object.keys(flattenedData).length : 0
			return {
				fingerprint_id: fingerprintId,
				name,
				hash,
				data: flattenedData,
				total_params: totalParams,
			}
		})

		const { error: detailsError } = await supabase.from('fp_details').insert(details)

		if (detailsError) {
			console.error('Error inserting details into Supabase:', detailsError)
			return
		}
	}

	console.log('Data inserted successfully')
}
