import { useEffect, useCallback, useState } from 'react';
import { useStore } from '@nanostores/react';
import { globalStore } from '~/store';
import debounce from 'lodash-es/debounce';
import { sendDataToSupabase } from '~/utils/sendDataToSupabase';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const StoreData = () => {
  const store = useStore(globalStore);

  console.log(store)
  const [isLoading, setIsLoading] = useState(true);

  const debouncedSendData = useCallback(
    debounce(async (store) => {
      await sendDataToSupabase(store);
      setIsLoading(false);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (store.webrtc !== undefined) {
      debouncedSendData(store);
    }
  }, [store]);
  

  return (
    <section className="border p-3">
      <h2 className="text-2xl font-semibold">Store Data</h2>
      {isLoading ? <p>Loading...</p> : <JsonView src={store} />}
      <pre>{store.hash}</pre>
    </section>
  );
};

export default StoreData;
